import { useMemo } from 'react'
import { useCachedSubscription } from './useSubscription'

const useSubscriptionEventTrackingParams = () => {
  const { data: subscription } = useCachedSubscription()
  const trackingParams = useMemo(() => {
    return {
      current_plan_tier_id: subscription?.user_current_plan?.tier_id,
      current_plan_tier_name: subscription?.user_current_plan?.tier_name,
      current_plan_interval: subscription?.user_current_plan?.interval,
      current_plan_interval_count:
        subscription?.user_current_plan?.interval_count,
      current_plan_start_date: subscription?.user_current_plan?.start_date,
      current_plan_end_date: subscription?.user_current_plan?.end_date,
      current_plan_amount: subscription?.user_current_plan?.amount,
      current_plan_currency: subscription?.user_current_plan?.currency,
      current_plan_product: subscription?.user_current_plan?.product,
      current_plan_price: subscription?.user_current_plan?.price,
      current_plan_active: subscription?.user_current_plan?.active,
      current_plan_allow_private_generation:
        subscription?.user_current_plan?.allow_private_generation,
      current_plan_allow_watermark_free_download:
        subscription?.user_current_plan?.allow_watermark_free_download,
      current_plan_max_concurrent_tasks:
        subscription?.user_current_plan?.max_concurrent_tasks,
      current_plan_max_generation_tasks:
        subscription?.user_current_plan?.max_generation_tasks,
      current_plan_max_queue_size:
        subscription?.user_current_plan?.max_queue_size,

      schedule_plan_tier_id: subscription?.user_next_plan?.tier_id,
      schedule_plan_tier_name: subscription?.user_next_plan?.tier_name,
      schedule_plan_interval: subscription?.user_next_plan?.interval,
      schedule_plan_interval_count:
        subscription?.user_next_plan?.interval_count,
      schedule_plan_start_date: subscription?.user_next_plan?.start_date,
      schedule_plan_end_date: subscription?.user_next_plan?.end_date,
      schedule_plan_amount: subscription?.user_next_plan?.amount,
      schedule_plan_currency: subscription?.user_next_plan?.currency,
      schedule_plan_product: subscription?.user_next_plan?.product,
      schedule_plan_price: subscription?.user_next_plan?.price,
      // schedule_plan_active: subscription?.user_next_plan?.active,
      schedule_plan_allow_private_generation:
        subscription?.user_next_plan?.allow_private_generation,
      schedule_plan_allow_watermark_free_download:
        subscription?.user_next_plan?.allow_watermark_free_download,
      schedule_plan_max_concurrent_tasks:
        subscription?.user_next_plan?.max_concurrent_tasks,
      schedule_plan_max_generation_tasks:
        subscription?.user_next_plan?.max_generation_tasks,
      schedule_plan_max_queue_size:
        subscription?.user_next_plan?.max_queue_size,
    }
  }, [subscription])
  return trackingParams
}

export default useSubscriptionEventTrackingParams
