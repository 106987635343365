import { cls } from '@/utils'
import Link from '@/components/link'
import { FC, useMemo } from 'react'
import DiscordIcon from '@haiper/icons-svg/icons/band/discord.svg'
import XIcon from '@haiper/icons-svg/icons/band/x.svg'
import InstagramIcon from '@haiper/icons-svg/icons/band/instagram.svg'
import IconTikTok from '@haiper/icons-svg/icons/band/tiktok.svg'
import { X_LINK, DISCORD_LINK, INSTAGRAM_LINK, TIKTOK_LINK } from '@/constants'

export interface SocialLinksProps {
  className?: string
}

export default function SocialLinks({ className }: SocialLinksProps) {
  const socialLinks: Array<{ Icon: FC<any>; link: string; testid: string }> = useMemo(() => {
    return [
      { Icon: XIcon, link: X_LINK, testid: 'x-link' },
      { Icon: InstagramIcon, link: INSTAGRAM_LINK, testid: 'instagram-link' },
      { Icon: IconTikTok, link: TIKTOK_LINK, testid: 'tiktok-link' },
      { Icon: DiscordIcon, link: DISCORD_LINK, testid: 'discord-link' },
    ]
  }, [])
  return (
    <div
      aria-label='social links'
      className={cls('hidden gap-4 md:flex items-center', className)}
      data-testid='social-links'
    >
      {socialLinks.map(({ Icon, link, testid }) => (
        <Link
          key={link}
          href={link}
          target='_blank'
          className='hidden md:flex last:flex items-center justify-center hover:no-underline hover:opacity-80 h-10'
          data-testid={testid}
        >
          <div className='flex items-center'>
            <Icon className='size-6 text-icon' />
          </div>
        </Link>
      ))}
    </div>
  )
}
