import { getMe } from '@/service/auth.service'
import { Me } from '@/types'
import { useCachedService } from '@banyudu/use-service'
// import { useAuth0 } from '@auth0/auth0-react'
import useAuth0Auth from './useAuth0Auth'

let promise: Promise<Me> | null = null

const conditionalGetMe = async (params?: {
  skip?: boolean
}): Promise<Me | null> => {
  if (params?.skip === true) {
    return null
  }

  if (!promise) {
    promise = getMe()
  }

  return promise
}

// const useMe = useService(conditionalGetMe, (p) => p?.skip !== true)

const _useCachedMe = useCachedService(conditionalGetMe, (p) => p?.skip !== true)

export const useCachedMe = () => {
  const { isLogin } = useAuth0Auth()
  return _useCachedMe({ skip: !isLogin })
}

// export default useMe
