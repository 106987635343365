import { useMemo } from 'react'
import useSubscriptionEventTrackingParams from './useSubscriptionEventTrackingParams'

const useCommonEventTrackingParams = () => {
  const subscriptionParams = useSubscriptionEventTrackingParams()

  const result = useMemo(() => {
    return {
      ...subscriptionParams,
    }
  }, [subscriptionParams])

  return result
}

export default useCommonEventTrackingParams
