import {
  auth0FirstLoginAtom,
  auth0Loading,
  auth0SignInAtom,
  loginDialogOpenAtom,
} from '@/atoms'
import { CACHE_AUTH_REDIRECT_URL } from '@/constants'
import { clearAuthCache, getLocalStorage } from '@/utils'
import {
  GetTokenSilentlyOptions,
  LogoutOptions,
  useAuth0,
} from '@auth0/auth0-react'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect } from 'react'

const useAuth0Auth = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
    error,
  } = useAuth0()

  const [isLogin, setIsLogin] = useAtom(auth0SignInAtom)
  const setIsLoadingAtom = useSetAtom(auth0Loading)
  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)
  const setAuth0FirstLogin = useSetAtom(auth0FirstLoginAtom)

  const login = useCallback(() => {
    const redirectUrl = `${window.location.pathname}${window.location.search}`
    sessionStorage.setItem(CACHE_AUTH_REDIRECT_URL, redirectUrl)
    setLoginDialogOpen(false)
    loginWithRedirect()
  }, [loginWithRedirect, setLoginDialogOpen])

  useEffect(() => {
    setIsLoadingAtom(isLoading)
  }, [isLoading, setIsLoadingAtom])

  const refreshToken = useCallback(
    (params?: GetTokenSilentlyOptions) => {
      if (!isAuthenticated) return Promise.resolve('not login')
      return getAccessTokenSilently(params).then((res: string) => {
        if (typeof res === 'string') {
          localStorage.setItem('token', res)
          setIsLogin(true)
        }
      })
    },
    [getAccessTokenSilently, setIsLogin, isAuthenticated],
  )

  const checkFirstLogin = useCallback(async () => {
    const idTokenPayload = await getIdTokenClaims()
    setAuth0FirstLogin(idTokenPayload?.first_login ?? false)
  }, [getIdTokenClaims, setAuth0FirstLogin])

  useEffect(() => {
    const token = getLocalStorage('token', { raw: true })
    if (!token && isAuthenticated) {
      refreshToken()
    } else if (token) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [isAuthenticated, refreshToken, setIsLogin])

  useEffect(() => {
    if (isAuthenticated) {
      checkFirstLogin()
    }
  }, [isAuthenticated, checkFirstLogin])

  const signOut = useCallback(
    (options?: LogoutOptions) => {
      logout(options).then(() => {
        clearAuthCache()
      })
    },
    [logout],
  )

  return {
    loginWithRedirect,
    isLogin,
    isLoading,
    logout: signOut,
    refreshToken,
    setIsLogin,
    error,
    login,
  }
}

export default useAuth0Auth
