import { forwardRef } from 'react'
import { Input as BaseInput, InputProps } from '../ui/input'
import { cls } from '@/utils'

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  return (
    <BaseInput
      ref={ref}
      className={cls('border border-border border-solid disabled:bg-surface-base', className)}
      {...props}
    />
  )
})

Input.displayName = 'Input'
export default Input
