import { useEffect } from 'react'
import { outputCacheAtom } from '@/atoms'
import { useSetAtom } from 'jotai'

export default function useAutoCleanOutputCache() {
  // clear all cache data
  const setOutputCache = useSetAtom(outputCacheAtom)

  useEffect(() => {
    return () => {
      setOutputCache({})
    }
  }, [setOutputCache])
}
