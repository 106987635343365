import { getTopCreations } from '@/service/creation.service'
import useInfinite from './useInfinite'
import useService from '@banyudu/use-service'
import useAuthedSkip from './useAuthedSkip'

const useTopCreations = useService(getTopCreations, useAuthedSkip())

export const useInfiniteTopCreations = useInfinite(useTopCreations, {
  rowKey: 'creation_id',
})
