import {
  CreditBalance,
  CreditPricingTable,
  StripePricing,
  SwitchSubscription,
  UserSubscription,
} from '@/types'
import { rest } from '@/utils'

export const getStripePricingTable = async (): Promise<StripePricing[]> => {
  const res = await rest.get('/v1/pay/get-pricing-table')
  return res.data
}

export const getStripeCheckoutSession = async (data: {
  price_lookup_key: string
  success_url: string
  cancel_url: string
}): Promise<{ url: string }> => {
  const res = await rest.post('/v1/pay/create-checkout-session', data)
  return res.data
}

export const getStripePortalSession = async (): Promise<{ url: string }> => {
  const res = await rest.post('/v1/pay/create-portal-session')
  return res.data
}

export const getStripeUserSubscription =
  async (): Promise<UserSubscription> => {
    const res = await rest.get('/v1/pay/get-user-subscription')
    return res.data
  }

export const getSwitchSubscriptionPlan = async (params: {
  price_lookup_key: string
}): Promise<SwitchSubscription> => {
  const res = await rest.get('/v1/pay/switch-subscription-plan', { params })
  return res.data
}

export const postSwitchSubscriptionPlan = async (data: {
  price_lookup_key: string
  subscription_proration_date?: number
}): Promise<any> => {
  const res = await rest.post('/v1/pay/switch-subscription-plan', data, {
    meta: { silent: true },
  } as any)
  return res.data
}

export const stopPendingCancellation = async (data: {
  subscription_id: string
}): Promise<any> => {
  const res = await rest.post('/v1/pay/stop-pending-cancellation', data)
  return res.data
}

export const cancelSubscriptionSchedule = async (data: {
  subscription_schedule_id: string
}): Promise<any> => {
  const res = await rest.post('/v1/pay/cancel-subscription-schedule', data)
  return res.data
}

export const getCreditBalance = async (): Promise<CreditBalance> => {
  const res = await rest.get('/v1/pay/get-user-credit-balance')
  return res.data
}

export const getCreditPricingTable = async (): Promise<CreditPricingTable> => {
  const res = await rest.get('/v1/pay/get-credit-pricing-table')
  return res.data
}

export const getCreditCheckoutSession = async (data: {
  price_lookup_key: string
  quantity: number
  success_url: string
  cancel_url: string
}): Promise<{ url: string }> => {
  const res = await rest.post('/v1/pay/create-credit-checkout-session', data)
  return res.data
}
