import { cls } from '@/utils'

export interface TheEndProps {
  className?: string
}

export default function TheEnd({ className }: TheEndProps) {
  return (
    <div
      className={cls(
        'w-full h-6 flex items-center justify-center text-text-subdued gap-2',
        className,
      )}
    >
      <div className='bg-text-subdued w-8 h-px' />
      <span>You’ve reached the end</span>
      <div className='bg-text-subdued w-8 h-px' />
    </div>
  )
}
