'use client'

import { useCachedMe } from './useMe'
import { useSearchParams } from 'next/navigation'
import axios from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { init, track as amplitudeTrack, getUserId, setUserId as setAmplitudeUserId } from '@amplitude/analytics-browser'
import { isProduction, utcDate, utcDateTime, utcTime, whisper, noop } from '@/utils'
import { AMPLITUDE_USER_CREATE_TIME_KEY, AMPLITUDE_USER_ID_KEY, UTM_FIELDS } from '@/constants'
import dayjs from 'dayjs'
import useCommonEventTrackingParams from '@/hooks/useCommonEventTrackingParams'

// filter events by name, to reduce the overall cost.
const EVENT_NAME_WHITE_LIST: string[] = [
  'view:membership:success',
  'view:auth:new-user-sign-in-successful',
  'click:creation:download',
  'click:creation:image:download',
  'click:creations:generate-after-signup-d1',
]

const AMPLITUDE_API_KEY: string = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string

let usingReverseProxy = false

const getLocalCommonTrackingParams = (): Record<string, any> => {
  const now = Date()
  const result: Record<string, any> = {
    PageURL: window.location.href,
    PageDomain: window.location.hostname,
    PagePath: window.location.pathname,
    PageLocation: window.location.origin,
    PageTitle: document.title,
    using_reverse_proxy: usingReverseProxy,
    referer: document.referrer,
    version: process.env.NEXT_PUBLIC_BUILD_ID,
    utc_date: utcDate(now),
    utc_time: utcTime(now),
    utc_datetime: utcDateTime(now),
  }
  try {
    const uid = localStorage.getItem(AMPLITUDE_USER_ID_KEY)
    if (uid) {
      result.uid = uid
    }

    const userCreateTime = localStorage.getItem(AMPLITUDE_USER_CREATE_TIME_KEY)
    if (userCreateTime) {
      const dateObj = dayjs(userCreateTime)
      result.user_create_time = utcDateTime(dateObj)
      result.user_create_date = utcDate(dateObj)
      result.is_new_user = utcDate(dateObj) === utcDate(now)
    }

    for (const utmKey of UTM_FIELDS) {
      const value = localStorage.getItem(utmKey)
      if (value) {
        result[utmKey] = value
      }
    }
  } catch (error) {
    return {}
  }
  return result
}

const setupAmplitude = async () => {
  try {
    await axios.post('https://api2.amplitude.com/2/httpapi', {
      api_key: AMPLITUDE_API_KEY,
      events: [],
      client_upload_time: new Date().toISOString(),
      options: {},
    })
  } catch (error) {
    whisper('Failed to ping Amplitude API', error)
    usingReverseProxy = true
  }

  const uid = localStorage.getItem(AMPLITUDE_USER_ID_KEY) ?? ''
  init(AMPLITUDE_API_KEY, uid, {
    defaultTracking: {
      sessions: true,
    },
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
    ...(usingReverseProxy
      ? {
          serverUrl: 'https://revproxy.haiper.ai/amp/2/httpapi',
        }
      : {}),
  })
}

let amplitudeSetupPromise: Promise<void> | null = null

const waitAmplitude = async () => {
  if (!isProduction) {
    return
  }

  if (!amplitudeSetupPromise) {
    amplitudeSetupPromise = setupAmplitude()
  }
  await amplitudeSetupPromise
}

export const setUserId = (id: string | undefined) => {
  setAmplitudeUserId(id)
  if (id === undefined) {
    localStorage.removeItem(AMPLITUDE_USER_ID_KEY)
  } else {
    localStorage.setItem(AMPLITUDE_USER_ID_KEY, id)
  }
}

export const setUserCreateTime = (time: string) => {
  if (!time) {
    localStorage.removeItem(AMPLITUDE_USER_CREATE_TIME_KEY)
    return
  }
  localStorage.setItem(AMPLITUDE_USER_CREATE_TIME_KEY, time)
}

const useAmplitude = () => {
  const { data: profile, isValidating: profileLoading } = useCachedMe()
  const profileLoadingRef = useRef(profileLoading)

  useEffect(() => {
    profileLoadingRef.current = profileLoading
  }, [profileLoading])

  const waitProfile = useCallback(() => {
    return new Promise<void>((resolve) => {
      if (!profileLoadingRef.current) {
        resolve()
      } else {
        const interval = setInterval(() => {
          if (!profileLoadingRef.current) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      }
    })
  }, [])

  // save querystring utm_source to localStorage
  const query = useSearchParams()
  useEffect(() => {
    for (const utmKey of UTM_FIELDS) {
      const value = query?.get(utmKey)
      if (value) {
        localStorage.setItem(utmKey, value)
      }
    }
  }, [query])

  useEffect(() => {
    if (profile?.id) {
      setUserId(profile.id)
    }
  }, [profile])

  useEffect(() => {
    waitAmplitude().catch(console.error)
  }, [])

  const commonTrackingParmas = useCommonEventTrackingParams()
  const commonTrackingParmasRef = useRef(commonTrackingParmas)

  useEffect(() => {
    commonTrackingParmasRef.current = commonTrackingParmas
  }, [commonTrackingParmas])

  const track = useCallback(
    async (eventName: string, eventProperties?: Record<string, any>) => {
      if (!EVENT_NAME_WHITE_LIST.includes(eventName)) {
        return
      }

      await waitAmplitude()
      await waitProfile()

      const finalEventProperties = {
        ...commonTrackingParmasRef.current,
        ...getLocalCommonTrackingParams(),
        ...eventProperties,
      }
      if (isProduction) {
        amplitudeTrack(eventName, finalEventProperties)
      } else {
        if (process.env.NODE_ENV !== 'test') {
          whisper('[Amplitude Event]', `(uid: ${getUserId()}):`, eventName, finalEventProperties)
        }
      }
    },
    [waitProfile],
  )

  return {
    track,
    setUserId,
    setUserCreateTime,
  }
}

export default useAmplitude
