import { useEffect } from 'react'
import { creationCacheAtom } from '@/atoms'
import { useSetAtom } from 'jotai'

export default function useAutoCleanCreationCache() {
  // clear all cache data
  const setCreationCache = useSetAtom(creationCacheAtom)

  useEffect(() => {
    return () => {
      setCreationCache({})
    }
  }, [setCreationCache])
}
