import useService from '@banyudu/use-service'
import {
  getTagCreations,
  // getTagCreationsSegment,
} from '@/service/creation.service'
import useInfinite from './useInfinite'

const useTagCreations = useService(getTagCreations, (params) =>
  Boolean(params.tag),
)

// const useTagCreationsSegment = useService(
//   getTagCreationsSegment,
//   (params) => Boolean(params.tag),
// )

export const useInfiniteTagCreations = useInfinite(useTagCreations, {
  rowKey: 'creation_id',
})

// const useInfiniteTagCreationsSegment = useInfiniteSegment(
//   useTagCreationsSegment,
//   {
//     rowKey: 'creation_id',
//   },
// )

// export default useTagCreations
