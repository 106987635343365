import { rest, safeJsonParse } from '@/utils'

export interface PredictParams<T = Record<string, any>> {
  deployment: string
  input: T
}

const predict = async <P, R = any>(data: PredictParams<P>): Promise<R> => {
  const res = await rest.post('/v1//g/cog/predict', data)
  return res.data
}

const preprocess = async <P, R = any>(data: P): Promise<R> => {
  const predictParams = {
    deployment: 'preprocess-service',
    input: {
      input_json: JSON.stringify(data),
    },
  }

  const res = await predict(predictParams)

  return res
}

export interface ProcessInpaintingVideoResp {
  first_frame_embedding_url: string
  output_video_url: string
}

export const processInpaintingVideo = async (
  inputVideo: string,
): Promise<ProcessInpaintingVideoResp | null> => {
  const params = {
    entry: 'process_inpainting_video',
    data: {
      input_video: inputVideo,
    },
  }

  const res: string = await preprocess<any, string>(params)
  return safeJsonParse(res)
}

export const samVitImageEmbedding = async (url: string) => {
  const predictParams = {
    deployment: 'sam-vit-image-embedding',
    input: {
      source_image: url,
    },
  }

  const res = await predict(predictParams)

  return res
}
