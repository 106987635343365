import { Profile, Me } from '@/types'
import { rest } from '@/utils'

export const discordAuth = async (code: string): Promise<string> => {
  const res = await rest.get('/v1/auth/discord', {
    params: {
      code,
      redirect_uri: `${window.location.origin}/auth/callback/discord`,
    },
  })
  return res.data
}

export const getMe = async (): Promise<Me> => {
  const res = await rest.get('/v1/user/me')
  return res.data
}

export const getProfile = async (): Promise<Profile> => {
  const res = await rest.get('/v1/user/profile')
  return res.data
}

export const updateProfile = async (data: Partial<Profile>): Promise<void> => {
  const res = await rest.put('/v1/user/profile', data)
  return res.data
}
