import { PoNVoid, Template } from '@/types'
import { calculateAspectRatio, cls, formatNumber, isVideoUrl, preventDefault, stopPropagation, whisper } from '@/utils'
import { likeTemplate, unlikeTemplate } from '@/service/template.service'
import ChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import { usePathname, useRouter } from 'next/navigation'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import Video from '../video'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
// import IconArrowRight from '@haiper/icons-svg/icons/outline/arrow-right.svg'
import Button from '../button'
import { useSetAtom } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom } from '@/atoms'
import IconFire2 from '@haiper/icons-svg/icons/outline/fire2.svg'
import ButtonGroup, { ButtonGroupItem } from '../button-group'
import IconHeartSolid from '@haiper/icons-svg/icons/solid/heart.svg'
import IconHeartOutline from '@haiper/icons-svg/icons/outline/heart.svg'
import IconChat from '@haiper/icons-svg/icons/outline/chat.svg'
import { useTemplateLikeCache } from '@/hooks/useLikeCache'
import { isNil } from 'lodash-es'
import useAmplitude from '@/hooks/useAmplitude'

export interface TemplateCardProps {
  className?: string
  data?: Template
  onCreate?: (template: Template) => PoNVoid
  style?: React.CSSProperties
  showCreationsNum?: boolean
  variant?: 'default' | 'compact'
  source: string
}

const pathnamesWithCreationInput = [/^\/$/, /^\/creations$/, /^\/explore$/, /^\/templates$/, /^\/template\/.+/]

export default function TemplateCard({
  className,
  data,
  onCreate,
  style,
  showCreationsNum,
  source,
  variant = 'default',
}: TemplateCardProps) {
  const trackingParams = useMemo(() => {
    return {
      source,
      template_id: data?.template_id,
      template_name: data?.name,
      template_category: data?.category,
    }
  }, [source, data])

  const router = useRouter()
  const { track } = useAmplitude()

  useEffect(() => {
    track('view:template:card', trackingParams)
  }, [trackingParams, track])

  const canGotoDetail = data?.template_id && !data?.template_id.startsWith('demo:')
  const setCreationInput = useSetAtom(creationInputAtom)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)
  const pathname = usePathname()
  const coverAspectStyle: CSSProperties = useMemo(() => {
    const { width, height } = data?.cover_image_spec ?? {}
    const aspectRatio = calculateAspectRatio(width, height)
    return {
      aspectRatio,
    }
  }, [data?.cover_image_spec])

  const isVertical = (data?.cover_image_spec?.height ?? 0) > (data?.cover_image_spec?.width ?? 0)

  const handleGotoDetail = useCallback(
    (action: string) => {
      track('click:template:card', { action, ...trackingParams })
      if (canGotoDetail) {
        router.push(`/template/${data?.template_id}`)
      }
    },
    [data, router, canGotoDetail, track, trackingParams],
  )

  const commentCount = data?.comment_num ?? 0

  const _liked = !!data?.commits?.is_like

  const likeCache = useTemplateLikeCache(data?.template_id ?? '')
  const liked = likeCache.liked ?? _liked

  const likedCountDelta = isNil(likeCache.liked) || likeCache.liked === _liked ? 0 : likeCache.liked ? 1 : -1

  const likeCount = Math.max(0, (data?.commits?.likes_count ?? 0) + likedCountDelta)

  const handleOpenComment = useCallback(() => {
    handleGotoDetail('open-comment')
  }, [handleGotoDetail])

  const toggleLike = useCallback(async () => {
    const action = liked ? 'unlike' : 'like'
    track('click:template:card', { action, ...trackingParams })
    likeCache.toggle(!liked)
    if (liked) {
      await unlikeTemplate(data?.template_id ?? '')
    } else {
      await likeTemplate(data?.template_id ?? '')
    }
  }, [liked, data, likeCache, track, trackingParams])

  const defaultIconColor = variant === 'compact' ? 'text-icon-on-color' : 'text-icon'
  const defaultTextColor = variant === 'compact' ? 'text-text-on-color' : 'text-text'
  const likedColor = variant === 'compact' ? 'text-red-600' : 'text-border-critical-hover'

  const creationNumStr = useMemo(() => {
    return formatNumber(data?.creation_num ?? 0)
  }, [data?.creation_num])

  const handleCreate = useCallback(
    (e: any) => {
      preventDefault(e)
      stopPropagation(e)

      track('click:template:card', { action: 'create', ...trackingParams })

      if (!data?.template_id) {
        return
      }

      setCreationInput((prev) => {
        return {
          ...prev,
          mode: undefined,
          expanded: true,
        }
      })

      setActiveTemplateId(data?.template_id)

      const needRedirect = !pathnamesWithCreationInput.some((p) => p.test(pathname))

      if (needRedirect) {
        router.push('/creations')
      }

      onCreate?.(data)
    },
    [data, router, pathname, setCreationInput, setActiveTemplateId, onCreate, track, trackingParams],
  )

  const rightButtons: ButtonGroupItem[] = useMemo(() => {
    const favoriteBtn: ButtonGroupItem = {
      key: 'favorite',
      icon: liked ? IconHeartSolid : IconHeartOutline,
      className: cls(defaultTextColor, ''),
      label: String(likeCount || ''),
      tooltip: { content: 'Like' },
      iconClassname: cls('size-5', liked ? likedColor : defaultIconColor),
      visible: true,
      onClick: toggleLike,
    }

    if (variant === 'compact') {
      return [
        {
          key: 'create',
          icon: IconFire2,
          className: cls(defaultTextColor, ''),
          label: creationNumStr,
          tooltip: { content: 'Create' },
          iconClassname: cls('size-5', defaultIconColor),
          visible: true,
          onClick: handleCreate,
        },
        favoriteBtn,
      ]
    }

    return [
      favoriteBtn,
      {
        key: 'comment',
        icon: IconChat,
        label: String(commentCount || ''),
        className: cls(defaultTextColor, ''),
        iconClassname: cls('size-5', defaultIconColor),
        tooltip: { title: 'Comment', content: '' },
        visible: true,
        onClick: handleOpenComment,
      },
    ]
  }, [
    commentCount,
    defaultIconColor,
    defaultTextColor,
    handleOpenComment,
    liked,
    likedColor,
    likeCount,
    toggleLike,
    variant,
    creationNumStr,
    handleCreate,
  ])

  const [coverLoadFailed, setCoverLoadFailed] = useState(false)

  const coverVideo = useMemo(() => {
    const mediaUrl = data?.examples?.[0]?.media_url ?? ''
    return isVideoUrl(mediaUrl) ? mediaUrl : ''
  }, [data?.examples])

  return (
    <div
      className={cls(
        'relative group rounded-xl p-2 bg-surface flex flex-col gap-2 cursor-pointer border-2 border-b-4 border-border hover:border-border-hover overflow-hidden h-max max-h-max',
        canGotoDetail ? 'cursor-pointer' : 'cursor-default',
        variant === 'compact' ? 'p-0 border rounded-md' : '',
        className,
      )}
      style={style}
      aria-label='template card'
      onClick={() => handleGotoDetail('click')}
    >
      {variant === 'default' && (
        <div className='flex flex-col items-center w-full justify-between text-text' aria-label='template card header'>
          <div className='flex items-center w-full justify-between h-8 md:px-1 text-text gap-2'>
            <span className='text-body-sm font-medium tracking-15 line-clamp-2 leading-4 md:leading-5'>
              {data?.name}
            </span>
            <div className='flex items-center gap-2'>
              <Button className='size-8 p-0' tooltip='View detail'>
                <ChevronRight className='size-5 text-icon' />
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className='relative z-0 w-full rounded-md overflow-hidden' style={coverAspectStyle}>
        {coverLoadFailed || (!data?.cover_image && !coverVideo) ? (
          <div
            className='w-full rounded-md overflow-hidden bg-surface-subdued flex items-center justify-center text-body-lg font-medium tracking-32'
            style={coverAspectStyle}
          >
            {data?.name}
          </div>
        ) : coverVideo ? (
          <Video
            loop
            // controls
            playOnHover
            resetOnBlur
            muted
            playsInline
            hasAudioTrack={false}
            src={coverVideo}
            poster={data?.cover_image}
            className='w-full h-full object-cover object-top'
            playClassName='hidden'
            onError={() => setCoverLoadFailed(true)}
            onClick={() => handleGotoDetail('click-video')}
          />
        ) : (
          <img
            src={data?.cover_image}
            alt={data?.name}
            className='w-full h-full object-scale-down'
            onError={() => setCoverLoadFailed(true)}
          />
        )}
      </div>
      {variant === 'default' && (
        <div className='flex flex-col items-center w-full justify-between text-text' aria-label='template card footer'>
          <div className='flex items-center w-full justify-between laptop:h-9 md:px-1 text-text gap-1 laptop:gap-2 flex-col laptop:flex-row'>
            {showCreationsNum && (
              <Button
                variant='outline'
                className='h-9 p-[6px] gap-0 w-full laptop:w-auto'
                tooltip='Create'
                tooltipProps={{ asChild: true }}
                onClick={handleCreate}
              >
                <IconFire2 className='size-5 text-icon' />
                <span className='px-1 text-icon'>{formatNumber(data?.creation_num ?? 0)}</span>
                <IconPlus className='size-5 text-icon ml-auto' />
              </Button>
            )}
            <ButtonGroup
              variant='transparent'
              className={cls('h-9 self-end', showCreationsNum ? '' : 'w-full justify-between')}
              items={rightButtons}
            />
          </div>
        </div>
      )}
      {variant === 'compact' && (
        <div className='z-10 absolute inset-x-0 bottom-0 h-14 bg-gradient-to-b from-transparent to-black/80 pointer-events-none'>
          <div
            className={cls(
              'z-10 absolute inset-x-2 bottom-2 flex text-text-on-color',
              isVertical ? 'flex-col' : 'flex-row h-7 items-center justify-between',
            )}
          >
            <span className={cls('px-1 font-medium', isVertical ? 'w-full' : 'flex-1 truncate')}>{data?.name}</span>
            <ButtonGroup
              variant='transparent'
              className={cls(
                'h-7 self-end',
                showCreationsNum || (variant === 'compact' && !isVertical) ? '' : 'w-full justify-between',
              )}
              itemClassName='text-text-on-color'
              iconClassname='text-icon-on-color'
              items={rightButtons}
            />
          </div>
        </div>
      )}
    </div>
  )
}
